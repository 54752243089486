import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchFormBase from '@websky/core/src/SearchForm/components/SearchForm/SearchForm';
import { setMultiCity } from '@websky/core/src/SearchForm/store/segments/actions';
const SearchFormWidget = React.memo(props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setMultiCity(false)); // ifly uses multiCity only in engine
    }, []);
    return React.createElement(SearchFormBase, Object.assign({}, props));
});
export default SearchFormWidget;
