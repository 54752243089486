import * as React from 'react';
import { initI18n } from '@websky/core/src/utils';
import Seat from '@websky/core/src/SeatMap/components/SeatMap/Seat/Seat';
import { Money } from '@websky/core/src/Money';
import css from './SeatsInfo.css';
import { useTranslation } from 'react-i18next';
import FreeSeatInfo from '@websky/core/src/SeatMap/components/SeatsInfo/FreeSeatInfo/FreeSeatInfo';
initI18n('SeatMap');
const SeatsInfo = ({ seatsByRfisc, passenger, filterUnavailable = true, showComfort = true, showFreeSeat }) => {
    const { t } = useTranslation('SeatMap');
    return (React.createElement(React.Fragment, null,
        Object.entries(seatsByRfisc)
            .filter(([_, seat]) => (filterUnavailable ? seat.isAvailable : true))
            .sort(([_a, a], [_b, b]) => { var _c, _d, _e, _f; return ((_d = (_c = b.price) === null || _c === void 0 ? void 0 : _c.amount) !== null && _d !== void 0 ? _d : 0) - ((_f = (_e = a.price) === null || _e === void 0 ? void 0 : _e.amount) !== null && _f !== void 0 ? _f : 0); })
            .map(([_, seat]) => (React.createElement("div", { key: seat.number, className: css.service },
            seat.serviceImage && React.createElement("img", { className: css.image, src: seat.serviceImage, alt: seat.name }),
            React.createElement("div", { className: css.card },
                React.createElement("div", { className: css.info },
                    React.createElement(Seat, { className: css.seat, seat: seat, onSelect: () => null, isAvailable: true, occupiedBy: null, passenger: passenger, passengerId: passenger.id, isSmall: true }),
                    showComfort && (seat === null || seat === void 0 ? void 0 : seat.seatService) && (React.createElement("div", { className: css.comfort }, t(`SeatMap_${seat.seatService.comfort}`))),
                    seat.price && React.createElement(Money, { moneyClassName: css.money, money: seat.price })),
                seat.description && React.createElement("div", { className: css.description }, seat.description))))),
        showFreeSeat && (React.createElement(FreeSeatInfo, { customTitle: t('SeatMap_Comfort'), className: css.freeSeatContainer, seatClassName: css.freeSeatItem }))));
};
export default SeatsInfo;
