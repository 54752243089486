import * as React from 'react';
import { BigPet, Bike, MediumPet, Serfing, SmallPet, SnowBoard, Ski, PetInHold, SportEquipment } from '@websky/core/src/Icons';
import { BaggageType } from '@websky/graphql';
export const Pet = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 500 500" },
    React.createElement("path", { d: "M207.1,335.58V288.3h-6.27v47.28a8.76,8.76,0,0,1-4.57,7.7h15.42A8.77,8.77,0,0,1,207.1,335.58Z", fill: "currentColor" }),
    React.createElement("path", { d: "M217.78,428.19a5.53,5.53,0,0,1-1.87,4.27,6.85,6.85,0,0,1-4.87,1.73,6.93,6.93,0,0,1-4.89-1.73,5.77,5.77,0,0,1,0-8.52,7,7,0,0,1,4.89-1.73,6.89,6.89,0,0,1,4.87,1.73A5.53,5.53,0,0,1,217.78,428.19Z", fill: "currentColor" }),
    React.createElement("path", { d: "M219.65,450.71a6.84,6.84,0,0,1-2.55,5.21,9.2,9.2,0,0,1-12.15,0,6.61,6.61,0,0,1,0-10.43,9.17,9.17,0,0,1,12.15,0A6.85,6.85,0,0,1,219.65,450.71Z", fill: "currentColor" }),
    React.createElement("path", { d: "M315.23,447.32a8.82,8.82,0,0,1-2.58,6.39,8.21,8.21,0,0,1-6,2.64,7.72,7.72,0,0,1-5.86-2.57,9.88,9.88,0,0,1,0-12.91,7.73,7.73,0,0,1,5.86-2.54,8.18,8.18,0,0,1,6,2.61A8.77,8.77,0,0,1,315.23,447.32Z", fill: "currentColor" }),
    React.createElement("path", { d: "M394.81,366a11.37,11.37,0,0,0,0-22.73h-79.5a69.88,69.88,0,0,0,41.41-113.37c-.22-.32-.45-.64-.71-.95a49.88,49.88,0,0,1-2.42-62.55,10,10,0,1,0-16-11.95A69.9,69.9,0,0,0,339,239.78a9.07,9.07,0,0,0,1,1.33,49.9,49.9,0,0,1-26.2,82.08V271c0-33.85-15-66.45-37.43-88.38C264.59,205.26,235.51,216.19,192,215a8.51,8.51,0,0,0-9,8.34V335.57a8.77,8.77,0,0,0,4.58,7.71H99.06a11.37,11.37,0,0,0,0,22.73h137v24.4H140.9c-25.5,0-46.16,24.84-46.2,55.51a250.05,250.05,0,0,0,304.47,4.72V446c0-30.71-20.69-55.6-46.2-55.6H257.78V366ZM196.26,343.28a8.76,8.76,0,0,0,4.57-7.7V288.31h6.27v47.27a8.77,8.77,0,0,0,4.58,7.7Zm-9.57,95.78-19.52,9,19.52,9v9.74l-32.27-15.38v-6.75l32.27-15.37Zm38,23.53a21.26,21.26,0,0,1-27.39,0,15.59,15.59,0,0,1-5.44-11.88,13.07,13.07,0,0,1,.82-4.62,13.74,13.74,0,0,1,2-3.63A22.32,22.32,0,0,1,197,440a13.37,13.37,0,0,1,2-1.58l.8-.44c-.14-.09-.35-.25-.62-.42a15.93,15.93,0,0,1-1.46-1.24,13.33,13.33,0,0,1-1.85-2.09,11.44,11.44,0,0,1-1.41-2.93,11.63,11.63,0,0,1-.63-3.84,13.31,13.31,0,0,1,5-10.23,19.48,19.48,0,0,1,24.59,0,13.29,13.29,0,0,1,5,10.23,12.61,12.61,0,0,1-4.5,9.46l-1.5,1.06c.19.1.46.25.82.48a19.88,19.88,0,0,1,1.9,1.5,14.83,14.83,0,0,1,2.4,2.56,15.69,15.69,0,0,1,1.89,3.59,12.67,12.67,0,0,1,.86,4.62A15.59,15.59,0,0,1,224.72,462.59ZM293,434.42c3.41-3.64,7.21-5.48,11.41-5.48a13.41,13.41,0,0,1,5.89,1.33,12.37,12.37,0,0,1,3.85,2.57l1.13,1.35v-4.5h10.11v33.75a17.91,17.91,0,0,1-5.55,13.36,19.16,19.16,0,0,1-13.93,5.39,22.29,22.29,0,0,1-6-.81,21.52,21.52,0,0,1-4.8-1.94,27.5,27.5,0,0,1-3.33-2.29,11.49,11.49,0,0,1-2-2l-.69-.84,6.37-6a14.92,14.92,0,0,0,1.12,1.35,12.81,12.81,0,0,0,3.38,2.19,12.32,12.32,0,0,0,5.64,1.33,9,9,0,0,0,9.74-9.75v-3a5.55,5.55,0,0,1-.45.58c-.21.23-.65.64-1.32,1.24a14.78,14.78,0,0,1-2.16,1.61,12.56,12.56,0,0,1-3,1.28,13.43,13.43,0,0,1-3.9.56c-4.2,0-8-1.81-11.41-5.46a18.88,18.88,0,0,1,0-25.83Zm-31.7-20.48v30.75l13.11-15H286l-13.84,15.77,14.61,21H275.5l-9.78-14.23-4.47,5.23v9H251.11v-52.5Z", fill: "currentColor" }),
    React.createElement("path", { d: "M283.41,172.24h-7.13a37.38,37.38,0,0,0,1.58-5h5.55a2.5,2.5,0,0,0,0-5h-4.76c.1-1.07.16-2.15.16-3.23V86a33,33,0,0,0-32.73,28.71H185.83a33.21,33.21,0,0,0-2.87-9.56A33.3,33.3,0,0,0,153.1,86v73c0,1.08.06,2.16.16,3.23h-5.83a2.5,2.5,0,0,0,0,5h6.63a34.7,34.7,0,0,0,1.58,5h-8.21a2.5,2.5,0,0,0,0,5h10.64A36.34,36.34,0,0,0,176.92,193a105.18,105.18,0,0,0,39,6.94A105.12,105.12,0,0,0,255,193a36.31,36.31,0,0,0,18.85-15.74h9.57a2.5,2.5,0,0,0,0-5Zm-97.7-2.5a15.13,15.13,0,1,1,15.12-15.13A15.13,15.13,0,0,1,185.71,169.74Zm34.9,3.24-2.89,2.89a2.5,2.5,0,0,1-3.53,0L211.31,173a2.5,2.5,0,1,1,3.53-3.54l1.12,1.12,1.11-1.12a2.5,2.5,0,1,1,3.54,3.54Zm25.6-3.24a15.13,15.13,0,1,1,15.12-15.13A15.13,15.13,0,0,1,246.21,169.74Z", fill: "currentColor" }),
    React.createElement("path", { d: "M196.4,154.61a10.7,10.7,0,1,1-10.69-10.69A10.7,10.7,0,0,1,196.4,154.61Z", fill: "currentColor" }),
    React.createElement("path", { d: "M256.9,154.61a10.7,10.7,0,1,1-10.69-10.69A10.7,10.7,0,0,1,256.9,154.61Z", fill: "currentColor" }),
    React.createElement("rect", { height: "38", width: "42", y: "429", x: "151", fill: "currentColor" }),
    React.createElement("rect", { height: "68", width: "56", y: "400", x: "181", fill: "currentColor" }),
    React.createElement("rect", { height: "63", width: "97", y: "408", x: "236", fill: "currentColor" }),
    React.createElement("rect", { height: "24", width: "44", y: "459", x: "282", fill: "currentColor" })));
const Paid = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.5284 13.3008H13.0057C13.8807 13.3008 14.6136 13.1426 15.2045 12.8262C15.7992 12.5059 16.2462 12.0723 16.5455 11.5254C16.8485 10.9746 17 10.3555 17 9.66797C17 8.98828 16.8504 8.37109 16.5511 7.81641C16.2557 7.26172 15.8106 6.82031 15.2159 6.49219C14.625 6.16406 13.8883 6 13.0057 6H8.77841V11.7656H7V13.3008H8.77841V14.3086H7V15.8496H8.77841V18H10.5284V15.8496H13.0909V14.3086H10.5284V13.3008ZM10.5284 11.7656H12.983C13.4981 11.7656 13.9242 11.6777 14.2614 11.502C14.5985 11.3223 14.8485 11.0762 15.0114 10.7637C15.1742 10.4473 15.2557 10.084 15.2557 9.67383C15.2557 9.26758 15.1742 8.9043 15.0114 8.58398C14.8485 8.26367 14.6004 8.01172 14.267 7.82812C13.9337 7.64062 13.5133 7.54688 13.0057 7.54688H10.5284V11.7656Z", fill: "currentColor" })));
const typesByRfics = {
    '0EF': SnowBoard,
    '0EG': SnowBoard,
    '0EH': SnowBoard,
    '0EC': Bike,
    '0EI': Serfing,
    '0DD': SnowBoard,
    '0BT': PetInHold,
    '09S': Pet,
    '021': SmallPet,
    '0BS': MediumPet,
    '0AY': BigPet,
    '0F8': Ski
};
export const overridedIcons = {
    Paid
};
const iconByBaggageType = {
    [BaggageType.PetInCabin]: PetInHold
};
export const renderHeaderEquipmentIcon = (rfics, baggageType) => {
    var _a;
    if (baggageType && iconByBaggageType[baggageType]) {
        return iconByBaggageType[baggageType];
    }
    return (_a = typesByRfics[rfics]) !== null && _a !== void 0 ? _a : SportEquipment;
};
