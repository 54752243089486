import SegmentTabs from './SegmentTabs.css';
const SegmentTabStyles = {
    SegmentTab: {
        segment: SegmentTabs.segment,
        checked: SegmentTabs.checked
    },
    SegmentTabs: {
        title: SegmentTabs.title,
        wrapper_center: SegmentTabs.wrapper_center,
        alignmentStub: SegmentTabs.alignmentStub,
        segments: SegmentTabs.segments
    }
};
export default SegmentTabStyles;
