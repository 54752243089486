import * as React from 'react';
import { useTranslation } from 'react-i18next';
import RootPassengers from '@websky/core/src/SeatMap/components/Passengers/Passengers';
import { InfoDetails } from '@websky/core/src/Icons';
import css from './Passengers.css';
const Passengers = props => {
    const { t } = useTranslation('SeatMap');
    const renderRules = React.useCallback(() => {
        return (React.createElement("a", { className: css.rules, href: "https://cdn.websky.aero/content/frontend/pdf/seat_map_rules_f7.pdf", target: "_blank" },
            InfoDetails,
            t('Rules for providing of services')));
    }, []);
    return React.createElement(RootPassengers, Object.assign({}, props, { renderFooterBefore: renderRules }));
};
export default Passengers;
