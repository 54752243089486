import cn from 'classnames';
import IncludedBaggage from './components/Baggage/IncludedBaggage/IncludedBaggage.css';
import AdditionalBaggage from './components/Baggage/AdditionalBaggage/AdditionalBaggage.css';
import BaggagePage from './components/Baggage/BaggagePage/BaggagePage.css';
import BaggageCommon from './components/Baggage/BaggageCommon.css';
import BaggageCounter from './components/BaggageCounter/BaggageCounter.css';
import SwitchV2 from './components/SwitchV2/SwitchV2.css';
import Counter from './components/Counter/Counter.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import SearchFormControls from './components/SearchForm/Controls/Controls.css';
import LocationAutocomplete from './components/LocationAutocomplete/LocationAutocomplete.css';
import SearchFormDatepicker from './components/SearchFormDatepicker/SearchFormDatepicker.css';
import Passengers from './components/Passengers/Passengers.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import PassengersDetails from './components/PassengersDetails/PassengersDetails.css';
import Checkout from './components/Checkout/Checkout.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import PriceGraph from './components/PriceGraph/PriceGraph.css';
import StepbarStep from './components/Stepbar/Step/Step.css';
import Results from './components/Results/Results.css';
import ResultsCalendar from './components/Results/Calendar/Calendar.css';
import ResultsSortings from './components/Results/Sortings/Sortings.css';
import ResultsFlightsList from './components/Results/FlightsList/FlightsList.css';
import ResultsMobilePriceGraph from './components/Results/MobilePriceGraph/MobilePriceGraph.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import WeekCalendarSlider from './components/WeekCalendar/Slider/Slider.css';
import WeekCalenderDay from './components/WeekCalendar/Day/Day.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import DesktopFlightRouteSegment from './components/DesktopFlightRoute/Segment/Segment.css';
import DesktopFlightRoutePoint from './components/DesktopFlightRoute/Point/Point.css';
import DesktopFlightRouteArrow from './components/DesktopFlightRoute/RouteArrow/RouteArrow.css';
import DesktopFlightRoutePrice from './components/DesktopFlightRoute/FlightPrice/FlightPrice.css';
import DesktopFlightRouteStopsInfo from './components/DesktopFlightRoute/StopsInfo/StopsInfo.css';
import DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import DesktopFlightHeader from './components/DesktopFlight/Header/Header.css';
import DesktopFlightLocation from './components/DesktopFlight/Location/Location.css';
import DesktopFlightSelectedFare from './components/DesktopFlight/SelectedFareGroup/SelectedFareGroup.css';
import DesktopFlightFlightInfoSegment from './components/DesktopFlight/FlightInfoSegment/FlightInfoSegment.css';
import MobileFlight from './components/MobileFlight/MobileFlight.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import FareGroupGrid from './components/FareGroup/FareGroupGrid/FareGroupGrid.css';
import FareGroupIcon from './components/FareGroup/FareGroupIcon/FareGroupIcon.css';
import FlightCard from './components/FlightCard/FlightCard.css';
import FareLock from './components/FareLock/FareLock.css';
import FareLockDescriptionBlock from './components/FareLock/DescriptionBlock/DescriptionBlock.css';
import FareLockSwitchBlock from './components/FareLock/SwitchBlock/SwitchBlock.css';
import ResendTicketsForm from './components/ResendTicketsForm/ResendTicketsForm.css';
import SeatStyles from './components/SeatMap/SeatMap';
import SegmentTabStyles from './components/SegmentTabs/SegmentTabs';
import MealServiceList from './components/MealService/Category/List/List.css';
import Meal from './components/Meal/Meal.css';
import SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import PaymentTimeLimit from './components/PaymentTimeLimit/PaymentTimeLimit.css';
import DesktopSummaryFlight from './components/DesktopSummaryFlight/DesktopSummaryFlight.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import Notification from './components/Notification/Notification.css';
import PassengerSwitch from './components/Passenger/Switch/Switch.css';
import Input from './components/Input/Input.css';
import CompareFares from './components/CompareFares/CompareFares.css';
import Modal from './components/Modal/Modal.css';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import QuickSearchFormSummary from './components/QuickSearchForm/Summary/Summary.css';
import DummyWeekCalendar from './components/DummyWeekCalendar/DummyWeekCalendar.css';
import InsurancePopup from './components/InsurancePopup/InsurancePopup.css';
import PromoLoader from './components/PromoLoader/PromoLoader.css';
import Checkin from './components/Checkin/Checkin.css';
import CheckinInitial from './components/Checkin/Initial/Initial.css';
import CheckinPassengers from './components/Checkin/Passengers/Passengers.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import Segment from './components/Segment/Segment.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import RegisteredPassengerService from './components/RegisteredPassenger/Service/Service.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import CustomerContactsForm from './components/CustomerContactsForm/CustomerContactsForm.css';
import Sortings from './components/Results/components/Results/FlightsList/Sortings/Sortings.css';
import FlightList from './components/Results/components/Results/FlightsList/FlightsList.css';
import MonthsWrapper from './components/Datepicker/components/MonthsWrapper/MonthsWrapper.css';
import InsuranceUpsale from './components/CheckoutAdditionalService/InsuranceUpsale/InsuranceUpsale.css';
import InsurancePage from './components/InsurancePage/InsurancePage.css';
import Page404 from './components/Page404/Page404.css';
import Exchange from './components/Exchange/Exchange.css';
import TicketRequest from './components/TicketRequest/TicketRequest.css';
import DesktopFlightModalContent from './components/DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent.css';
import Disabilities from './components/PassengerForm/components/components/Disabilities/Disabilities.css';
import PassengersToolbar from './components/Checkout/components/Checkout/Form/PassengersToolbar/PassengersToolbar.css';
import FareConditions from './components/FareConditions/FareConditions.css';
import MobileAuthForm from './components/MobileAuthForm/MobileAuthForm.css';
import LoginPage from './components/LoginPage/LoginPage.css';
const theme = {
    QuickSearchForm: {
        QuickSearchForm: {
            inner: QuickSearchForm.inner,
            form: QuickSearchForm.form
        },
        Summary: {
            mobile: QuickSearchFormSummary.mobile,
            route: QuickSearchFormSummary.route,
            route__icon: QuickSearchFormSummary.route__icon,
            passengers: QuickSearchFormSummary.passengers
        }
    },
    Baggage: {
        Baggage: {
            icon: BaggageCommon.icon,
            size: BaggageCommon.baggage_size,
            name: BaggageCommon.baggage_name
        },
        BaggageIcon: {
            root: BaggageCommon.baggage_icon__root,
            wrapper: BaggageCommon.baggage_icon,
            size: BaggageCommon.baggage_icon__size,
            value: BaggageCommon.baggage_icon__value,
            BaggageExcess: BaggageCommon.baggageExcess,
            prefix: BaggageCommon.baggage_icon__prefix
        }
    },
    BaggageCard: {
        BaggageCard: {
            icon: BaggageCommon.icon,
            button: BaggageCommon.button,
            header: BaggageCommon.header,
            price: BaggageCommon.total_price,
            description: BaggageCommon.subtext,
            wrapper: BaggageCommon.baggage_card_wrapper,
            info: BaggageCommon.baggage_card_info
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            control__edit: BaggageCommon.control__edit,
            control: BaggageCommon.control,
            price: BaggageCommon.total_price
        }
    },
    BaggagePage: {
        BaggagePage: {
            baggage: BaggagePage.baggage,
            additionalBaggage: BaggageCommon.root
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            image: BaggageCommon.banner_image,
            text: BaggageCommon.banner_text,
            wrapper: BaggageCommon.banner_wrapper,
            extrasBanner: cn(Checkin.extrasBanner),
            content: cn(Checkin.content)
        }
    },
    OverweightBaggage: {
        OverweightBaggage: {
            flight_direction: BaggageCommon.direction,
            flight_count: BaggageCommon.flight_count,
            flight_icon: BaggageCommon.flight_icon
        }
    },
    SpecialEquipment: {
        SpecialEquipment: {
            flight_direction: BaggageCommon.direction,
            flight_count: BaggageCommon.flight_count,
            flight_icon: BaggageCommon.flight_icon
        }
    },
    BaggageList: {
        BaggageList: {
            item: BaggageCommon.item,
            add: BaggageCommon.baggage_add_icon,
            root: BaggageCommon.baggageList_root
        },
        BaggageCount: {
            root: BaggageCommon.options__succsess
        },
        BaggageDirection: {
            root: BaggageCommon.options__text__succsess
        }
    },
    BaggageCounter: {
        BaggageCounter: {
            root: BaggageCounter.root,
            minus: BaggageCounter.minus,
            plus: BaggageCounter.plus
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            root: BaggageCommon.root,
            header: AdditionalBaggage.header
        },
        PromoMessage: {
            root: AdditionalBaggage.promo_message
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            root: cn(BaggageCommon.root, BaggageCommon.includedBaggage__root),
            header: IncludedBaggage.header
        }
    },
    UpgradeBaggageDialog: {
        UpgradeBaggageDialog: {
            body: BaggageCommon.baggage_dialog_body,
            modalHeader: BaggageCommon.baggage_dialog_header_modal,
            close: BaggageCommon.baggage_dialog_header_modal_close,
            root: BaggageCommon.baggage_dialog_root
        },
        BaggageDialogHeader: {
            baggage_wrapper: BaggageCommon.baggage_dialog_header_wrapper,
            price_label: BaggageCommon.baggage_dialog_price_label,
            description: BaggageCommon.baggage_dialog_description,
            toggle__wrapper: BaggageCommon.baggage_dialog_header_modal_close,
            label: BaggageCommon.baggage_dialog_header_label,
            category_title: BaggageCommon.baggage_dialog_header_category,
            equipment: BaggageCommon.baggage_dialog_header_equipment
        },
        HeaderEquipmentItem: {
            wrapper: BaggageCommon.equipment_wrapper,
            active: BaggageCommon.equipment_active,
            selected: BaggageCommon.equipment_selected
        },
        BaggageSegment: {
            root: BaggageCommon.baggage_dialog_body_segment,
            price: BaggageCommon.baggage_price__right
        },
        BaggagePassenger: {
            name: BaggageCommon.baggage_dialog_body_passenger,
            number: BaggageCommon.baggage_dialog_body_passenger_number,
            checkbox_root: BaggageCommon.passenger_checkbox,
            checkbox_rootChecked: BaggageCommon.passenger_checkbox_checked,
            counter_wrapper: BaggageCommon.counter_wrapper
        },
        BaggageFooter: {
            root: BaggageCommon.baggage_dialog_footer,
            price_wrapper: BaggageCommon.baggage_dialog_footer_price,
            clear: BaggageCommon.baggage_dialog_footer_clear,
            confirm_btn: BaggageCommon.baggage_dialog_footer_confirm,
            cancel_btn: BaggageCommon.baggage_dialog_footer_cancel
        }
    },
    Switch: {
        SwitchV2: {
            primary: SwitchV2.primary,
            active: SwitchV2.active
        }
    },
    Counter: {
        Counter: {
            button: Counter.button,
            button_disabled: cn(Counter.button_disabled, BaggageCounter.button_disabled)
        }
    },
    FareConditions: {
        FareConditions: {
            segmentsTabs: FareConditions.segmentsTabs,
            segmentTabsTab: FareConditions.segmentTabsTab,
            segmentTabsTab__active: FareConditions.segmentTabsTab__active
        }
    },
    SegmentTabs: SegmentTabStyles,
    SeatMap: SeatStyles,
    Toolbar: {
        Toolbar: {
            toolbar: Toolbar.toolbar,
            price: Toolbar.price,
            backButton: Toolbar.backButton,
            backButton__wrapper: Toolbar.backButton__wrapper,
            button: Toolbar.button
        }
    },
    SearchForm: {
        SearchForm: {
            searchForm: cn(SearchForm.searchForm__searchForm, QuickSearchForm.searchForm)
        },
        Value: {
            value: SearchForm.value__value,
            code: SearchForm.value__code,
            wrapper_alternative: SearchForm.value__wrapperAlternative,
            withMultiCity: SearchForm.value__withMultiCity
        },
        Datepicker: {
            dates: SearchForm.datepicker__dates,
            dates__to_focused: SearchForm.datepicker__datesToFocused,
            dates__back_focused: SearchForm.datepicker__datesBackFocused,
            returnDelete: SearchForm.datepicker__returnDelete,
            dates_focused: SearchForm.datepicker__datesFocused,
            alternative_dow: SearchForm.datepicker__alternativeDow,
            alternative: SearchForm.datepicker__alternative,
            dow: SearchForm.datepicker__dow,
            dates__to_text: SearchForm.datepicker__datesToText,
            dates__to: SearchForm.datepicker__datesTo,
            oneDate: SearchForm.datepicker__oneDate
        },
        Passengers: {
            selector: SearchForm.passengers__selector,
            passengers: SearchForm.passengers__passengers,
            mobileTitle: SearchForm.passengers__mobileTitle,
            selector_focused: SearchForm.passengers__selectorFocused
        },
        Currency: {
            selector: SearchForm.currency__selector,
            item: SearchForm.currency__item
        },
        Location: {
            switcher_highlighted: SearchForm.location__switcherHighlighted,
            switcher: SearchForm.location__switcher,
            switcher_alternative: SearchForm.location__switcherAlternative
        },
        MultiCity: {
            caption: cn(SearchForm.multiCity__caption, QuickSearchForm.multiCity__caption),
            multiCity: SearchForm.multiCity__multiCity
        },
        Bottom: {
            controls: SearchForm.bottom__controls
        },
        Actions: {
            addSegment: cn(QuickSearchForm.addSegment),
            addSegment_alternative: SearchForm.actions__addSegmentAlternative,
            removeSegment_alternative: SearchForm.actions__removeSegmentAlternative
        },
        Segment: {
            alternative: SearchForm.segment__alternative,
            withMultiCity: SearchForm.segment__withMultiCity,
            cell_location: SearchForm.segment__cellLocation,
            input: SearchForm.segment__input,
            cell_date: SearchForm.segment__cellDate,
            remover: cn(SearchForm.segment__remover, QuickSearchForm.segment__remover)
        },
        Controls: {
            controls: SearchForm.controls__controls,
            controls_withCurrency: SearchForm.controls__controlsWithCurrency,
            controls_multiCity: SearchFormControls.controls_multiCity
        },
        StartSearch: {
            startSearch: SearchForm.startSearch__startSearch,
            startSearch_iconMode: SearchForm.startSearch__startSearchIconMode
        }
    },
    LocationAutocomplete: {
        Group: {
            group__label: LocationAutocomplete.group__groupLabel
        },
        Option: {
            code: LocationAutocomplete.option__code,
            option: LocationAutocomplete.option__option,
            info: LocationAutocomplete.option__info
        },
        Dropdown: {
            allDirections__button: LocationAutocomplete.dropdown__allDirectionsButton,
            options: LocationAutocomplete.dropdown__options
        },
        DirectionsDialog: {
            dialog: LocationAutocomplete.directionsDialog__dialog,
            label: LocationAutocomplete.directionsDialog__label,
            country: LocationAutocomplete.directionsDialog__country,
            country_active: LocationAutocomplete.directionsDialog__countryActive,
            city: LocationAutocomplete.directionsDialog__city,
            iata: LocationAutocomplete.directionsDialog__iata
        },
        MobileDialog: {
            hint: LocationAutocomplete.mobileDialog__hint
        }
    },
    SearchFormDatepicker: {
        DayStyles: {
            day_withPrice: SearchFormDatepicker.dayStyles__dayWithPrice,
            day_startPeriod: SearchFormDatepicker.dayStyles__dayStartPeriod,
            day_selected: SearchFormDatepicker.dayStyles__daySelected,
            day_endPeriod: SearchFormDatepicker.dayStyles__dayEndPeriod,
            day__price: SearchFormDatepicker.dayStyles__dayPrice
        },
        MonthHeaderStyles: {
            monthHeader__select_month: SearchFormDatepicker.monthHeaderStyles__monthHeaderSelectMonth,
            monthHeader__select_year: SearchFormDatepicker.monthHeaderStyles__monthHeaderSelectYear
        },
        MonthsWrapperStyles: {
            nav_prev: SearchFormDatepicker.monthsWrapper__navPrev,
            nav_next: SearchFormDatepicker.monthsWrapper__navNext
        },
        WeeklyHeaderStyles: {
            day: SearchFormDatepicker.weeklyHeaderStyles__day,
            dayOfWeekName_withPrices: SearchFormDatepicker.weeklyHeaderStyles__dayOfWeekNameWithPrices
        },
        SearchFormDatepickerStyles: {
            footer__done: SearchFormDatepicker.searchFormDatepickerStyles__footerDone,
            dialog__activeDate: SearchFormDatepicker.searchFormDatepickerStyles__dialogActiveDate,
            dialog__dates: SearchFormDatepicker.searchFormDatepickerStyles__dialogDates,
            footer__clear: SearchFormDatepicker.searchFormDatepickerStyles__footerClear
        }
    },
    Passengers: {
        PassengerStyles: {
            increase: Passengers.passengerStyles__increase,
            decrease: Passengers.passengerStyles__decrease,
            counter: Passengers.passengerStyles__counter,
            passengerType: Passengers.passengerStyles__passengerType,
            age: Passengers.passengerStyles__age
        }
    },
    Exchange: {
        SelectedFlights: {
            total: Exchange.selectedFlights__total
        },
        RequestForm: {
            order: Exchange.requestForm__order
        }
    },
    OrderCard: {
        OrderStatus: {
            root: OrderCard.orderStatus__root,
            type_review: OrderCard.orderStatus__type_review,
            confirmed: OrderCard.orderStatus__confirmed,
            icon: OrderCard.orderStatus__icon
        },
        OrderCard: {
            root: OrderCard.orderCard__root,
            container: OrderCard.container
        },
        OrderPreview: {
            root: OrderCard.preview,
            inner: OrderCard.orderPreview__inner
        },
        OrderBooking: {
            orderCode: OrderCard.orderBooking__orderCode,
            root: OrderCard.orderBooking__root,
            type_review: OrderCard.orderBooking__type_review,
            download: OrderCard.orderBooking__download
        },
        OrderChange: {
            button: OrderCard.orderChange__button
        },
        OrderPassengers: {
            control: OrderCard.orderPassengers__control
        },
        OrderTickets: {
            link: OrderCard.orderTickets__link
        },
        PaymentStatus: {
            icon: OrderCard.paymentStatus__icon,
            root: OrderCard.paymentStatus__root
        },
        OrderCardControls: {
            root: OrderCard.orderCardControls__root,
            button: OrderCard.orderCardControls__button
        },
        CommonItem: {
            buttons: OrderCard.commonItem__buttons
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            close: ResendTicketsForm.close,
            footer: ResendTicketsForm.footer
        }
    },
    PassengersDetails: {
        PassengersDetails: {
            wrapper: PassengersDetails.wrapper,
            title: PassengersDetails.title,
            info: PassengersDetails.info,
            number: PassengersDetails.number,
            close: PassengersDetails.close
        }
    },
    PassengerForm: {
        PassengerForm: {
            form: PassengerForm.form
        },
        Header: {
            header: PassengerForm.header,
            filled: PassengerForm.header__filled,
            number: PassengerForm.header__number
        },
        Disabilities: {
            container: Disabilities.container,
            disabilities: PassengerForm.disabilities,
            checkboxes: PassengerForm.checkboxes
        },
        LoyaltyGroup: {
            loyalty__desc: PassengerForm.loyalty__desc
        },
        Switch: {
            toggle_active: PassengerForm.toggle_active
        },
        Group: {
            container: PassengerForm.group_container
        }
    },
    Checkout: {
        CheckoutStyles: {
            orderMessages: Checkout.orderMessages,
            form: Checkout.form,
            container_seats: cn(SegmentTabs.container_seats),
            gdsTotalPrice: Checkout.gdsTotalPrice,
            container: Checkout.container
        },
        Consents: {
            checkbox: Checkout.consents__checkbox,
            checkbox_checked: Checkout.consents__checkbox_checked
        },
        Stepbar: {
            additional: Checkout.stepbar__additional
        },
        PassengersToolbar: {
            toolbarCheckbox__wrapper: PassengersToolbar.toolbar__wrapper,
            label: Checkout.passengersToolbar__label
        },
        PassengersStyles: {
            passengers: Checkout.passengers
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            service: CheckoutAdditionalService.service,
            service_active: CheckoutAdditionalService.service_active,
            header: CheckoutAdditionalService.header,
            icon: CheckoutAdditionalService.icon,
            header__text: CheckoutAdditionalService.header__text,
            header__price: CheckoutAdditionalService.header__price,
            main: CheckoutAdditionalService.main,
            content: CheckoutAdditionalService.content,
            buttons: CheckoutAdditionalService.buttons,
            add: CheckoutAdditionalService.add
        },
        CheckoutAdditionalServiceMeal: {
            meal: CheckoutAdditionalService.meal
        },
        CheckoutAdditionalServiceBaggage: {
            baggage: CheckoutAdditionalService.baggage
        },
        Seats: {
            seats: CheckoutAdditionalService.seats
        },
        Insurance: {
            insurance: CheckoutAdditionalService.insurance
        },
        Business: {
            business: CheckoutAdditionalService.business
        },
        Loyalty: {
            loyalty: CheckoutAdditionalService.loyalty
        },
        Aeroexpress: {
            aeroexpress: CheckoutAdditionalService.aeroexpress
        },
        PriorityBoarding: {
            priority: CheckoutAdditionalService.priority
        },
        InsuranceUpsale: {
            container: InsuranceUpsale.container,
            oldVersion: InsuranceUpsale.oldVersion,
            change: InsuranceUpsale.change,
            paper: InsuranceUpsale.paper,
            footer: InsuranceUpsale.footer
        }
    },
    InsurancePage: {
        InsurancePage: {
            wrapper: cn(InsurancePage.wrapper, InsuranceUpsale.wrapper),
            header: cn(InsuranceUpsale.header),
            insurancesList: cn(InsuranceUpsale.insurancesList),
            title: InsurancePage.title
        },
        Card: {
            card: cn(InsuranceUpsale.card)
        },
        InsuranceProduct: {
            border: cn(InsuranceUpsale.insuranceProduct),
            selectButton: InsurancePage.selectButton
        },
        InsuranceUpsaleModal: {
            root: InsurancePage.insuranceUpsale__modal
        }
    },
    PriceGraph: {
        PriceGraphStyles: {
            graphHeader: PriceGraph.graphHeader,
            calendar_longPrice: PriceGraph.calendar_longPrice,
            graphHeader_longPrice: PriceGraph.graphHeader_longPrice
        }
    },
    Stepbar: {
        Step: {
            id: StepbarStep.id,
            step: StepbarStep.step,
            step_active: StepbarStep.step_active,
            step_passed: StepbarStep.step_passed,
            step_disabled: StepbarStep.step_disabled
        },
        Stepbar: {
            stepbar: StepbarStep.stepbar,
            cart: StepbarStep.cart
        }
    },
    Results: {
        SelectedFares: {
            wrapper: Results.selectedFares__wrapper
        },
        Results: {
            messages: Results.messages,
            results: Results.results,
            header: Results.header,
            header__stepbar: Results.header__stepbar,
            inner: Results.inner,
            toolbar__backButton: Results.toolbar__backButton,
            outer_bottom: Results.outer_bottom
        },
        Calendar: {
            legNumber: ResultsCalendar.legNumber,
            title__text: ResultsCalendar.title__text,
            title__cities: ResultsCalendar.title__cities,
            diagram: ResultsCalendar.diagram
        },
        Sortings: {
            sortings: Sortings.sortings,
            title: ResultsSortings.title,
            title_open: ResultsSortings.title_open,
            currentSorting: ResultsSortings.currentSorting,
            flightType: ResultsSortings.flightType,
            flightType__active: ResultsSortings.flightType__active,
            paper: ResultsSortings.paper,
            mobile__list__button: ResultsSortings.mobile__list__button,
            mobile__list__button_inactive: ResultsSortings.mobile__list__button_inactive
        },
        FlightsList: {
            wrapper: cn(Results.flightList),
            controls__wrapper: cn(ResultsFlightsList.controls__wrapper, FlightList.controls__wrapper),
            leg: ResultsFlightsList.leg
        },
        MobilePriceGraph: {
            dates: ResultsMobilePriceGraph.dates
        },
        DummyWeekCalendar: {
            wrapper: DummyWeekCalendar.wrapper,
            item: DummyWeekCalendar.item,
            item__head: DummyWeekCalendar.item__head,
            item__middle: DummyWeekCalendar.item__middle
        }
    },
    WeekCalendar: {
        WeekCalendarStyles: {
            dayWrapper: cn(WeekCalendar.dayWrapper)
        },
        SliderStyles: {
            arrow_prev: WeekCalendarSlider.arrow_prev,
            arrow_next: WeekCalendarSlider.arrow_next
        },
        DayStyles: {
            day: cn(WeekCalenderDay.day, WeekCalendar.day),
            day_selected: WeekCalenderDay.day_selected,
            day_notAvailable: WeekCalenderDay.day_notAvailable,
            day_best: WeekCalenderDay.day_best,
            date: WeekCalenderDay.date,
            date__dow: WeekCalenderDay.date__dow,
            date__info: WeekCalenderDay.date__info,
            price__wrapper: WeekCalenderDay.price__wrapper,
            price: WeekCalenderDay.price
        }
    },
    DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRoute.flight,
            selected: DesktopFlightRoute.selected,
            dropdown__icon: DesktopFlightRoute.dropdown__icon
        },
        SegmentStyles: {
            flightInfo: DesktopFlightRouteSegment.flightInfo,
            flightInfo__date: DesktopFlightRouteSegment.flightInfo__date,
            flightInfo__airline: DesktopFlightRouteSegment.flightInfo__airline,
            aircraft: DesktopFlightRouteSegment.aircraft,
            segment: DesktopFlightRouteSegment.segment,
            segment_additional: DesktopFlightRouteSegment.segment_additional,
            totalDuration__decorator: DesktopFlightRouteSegment.totalDuration__decorator,
            flightInfo__nextDay: DesktopFlightRouteSegment.flightInfo__nextDay,
            planeInfo: DesktopFlightRouteSegment.planeInfo
        },
        PointStyles: {
            time: DesktopFlightRoutePoint.time,
            airport: DesktopFlightRoutePoint.airport,
            point_thin: DesktopFlightRoutePoint.point_thin,
            date__container: DesktopFlightRoutePoint.date__container,
            point_arrival: DesktopFlightRoutePoint.point_arrival
        },
        RouteArrowStyles: {
            container: DesktopFlightRouteArrow.routeArrow__container,
            timeInRoute: DesktopFlightRouteArrow.timeInRoute,
            flightNumber: DesktopFlightRouteArrow.flightNumber
        },
        FlightPriceStyles: {
            price: DesktopFlightRoutePrice.price,
            price_selected: DesktopFlightRoutePrice.price_selected,
            prices__wrp: DesktopFlightRoutePrice.prices__wrp,
            price__money: DesktopFlightRoutePrice.price__money,
            fare_family: DesktopFlightRoutePrice.fare_family,
            button: DesktopFlightRoutePrice.button
        },
        StopsInfoStyles: {
            stop: DesktopFlightRouteStopsInfo.stop
        }
    },
    DesktopFlight: {
        ModalContent: {
            content_wrapper: DesktopFlightModalContent.content__wrapper,
            paper: DesktopFlight.paper,
            buttons: DesktopFlight.buttons,
            compareFaresLink: DesktopFlight.compareFaresLink,
            compareFaresLink_icon: DesktopFlight.compareFaresLink_icon,
            fareGroup__button: DesktopFlight.fareGroup__button,
            fareGroup__button_active: DesktopFlight.fareGroup__button_active,
            scroll_up: DesktopFlight.scroll_up,
            pagination__index: DesktopFlight.pagination__index
        },
        DesktopFlightModalHeader: {
            container: DesktopFlightHeader.container,
            title: DesktopFlightHeader.title
        },
        Location: {
            time: DesktopFlightLocation.time,
            date: DesktopFlightLocation.date,
            date_icon: DesktopFlightLocation.date_icon,
            city: DesktopFlightLocation.city,
            terminal: DesktopFlightLocation.terminal,
            time_iata: DesktopFlightLocation.time_iata,
            isMobile: DesktopFlightLocation.isMobile
        },
        SelectedFareGroup: {
            fare: cn(DesktopFlightSelectedFare.fare, FareGroup.selected),
            button: DesktopFlightSelectedFare.button
        },
        FlightInfoSegment: {
            card: DesktopFlightFlightInfoSegment.card,
            isMobile: DesktopFlightFlightInfoSegment.isMobile
        }
    },
    CompareFares: {
        CompareFares: {
            slider: CompareFares.slider,
            compareFares: CompareFares.compareFares,
            groupTitle: CompareFares.groupTitle,
            tableRow: CompareFares.tableRow
        }
    },
    MobileFlight: {
        FlightStyles: {
            flight: MobileFlight.flight,
            price: MobileFlight.price
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup_white: FareGroup.fareGroup_white,
            fareGroup_recommended: FareGroup.fareGroup_recommended,
            fareGroup_Business: FareGroup.fareGroup_Business,
            fareGroup_unavailable: FareGroup.fareGroup_unavailable,
            option: FareGroup.option,
            option_unavailable: FareGroup.option_unavailable,
            option__title: FareGroup.option__title,
            option__value: FareGroup.option__value,
            options: FareGroup.options,
            button: FareGroup.button,
            keyFeature: FareGroup.keyFeature,
            price: FareGroup.price
        },
        FareGroupGrid: {
            header: FareGroupGrid.header,
            option: FareGroupGrid.option,
            not_available: FareGroupGrid.not_available,
            small: FareGroupGrid.small,
            paid: FareGroupGrid.paid,
            upgradeOffer: FareGroupGrid.upgradeOffer
        },
        FareGroupIcon: {
            container: FareGroupIcon.container
        }
    },
    FlightCard: {
        FlightCard: {
            airline__name: FlightCard.airline__name,
            airline__number: FlightCard.airline__number,
            aircraft__name: FlightCard.aircraft__name,
            flight: FlightCard.flight
        }
    },
    FareLock: {
        FareLock: {
            root: FareLock.root
        },
        DescriptionBlock: {
            root: FareLockDescriptionBlock.root,
            icon: FareLockDescriptionBlock.icon,
            title: FareLockDescriptionBlock.title,
            description: FareLockDescriptionBlock.description
        },
        SwitchBlock: {
            root: FareLockSwitchBlock.root,
            switch_wrapper: FareLockSwitchBlock.switch_wrapper,
            switch_label: FareLockSwitchBlock.switch_label,
            switch_control: FareLockSwitchBlock.switch_control,
            switch_active: FareLockSwitchBlock.switch_active,
            price_wrapper: FareLockSwitchBlock.price_wrapper,
            price_label: FareLockSwitchBlock.price_label,
            price: FareLockSwitchBlock.price
        }
    },
    MealService: {
        List: {
            item: MealServiceList.item,
            item_selected: MealServiceList.item_selected,
            item__icon: MealServiceList.item__icon
        }
    },
    Meal: {
        Meal: {
            meal: Meal.meal,
            name: Meal.name,
            price: Meal.price,
            count: Meal.count,
            header: Meal.header
        },
        Details: {
            name: Meal.details__name,
            trigger: Meal.details__trigger,
            counter: Meal.details__counter,
            addForAll: Meal.details__addForAll,
            price: Meal.details__price,
            button_empty: Meal.details__button_empty,
            clear: Meal.details__clear,
            selector: Meal.details__selector,
            passenger: Meal.details_passenger,
            passenger__index: Meal.details_passenger__index,
            totalText: Meal.details__totalText,
            total: Meal.details__total,
            footer__price: Meal.details__footerPrice
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_back: MobileToolbar.button_back,
            button_continue: MobileToolbar.button_continue
        }
    },
    PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.wrapper,
            details: PaymentForm.details,
            oneMethod: PaymentForm.oneMethod,
            content__title: PaymentForm.content__title,
            details__button: PaymentForm.details__button,
            content: PaymentForm.content
        }
    },
    PaymentTimeLimit: {
        PaymentTimeLimit: {
            wrapper: PaymentTimeLimit.wrapper
        }
    },
    Notification: {
        Notification: {
            context: Notification.context,
            info: Notification.info,
            icon: Notification.icon,
            content: Notification.content,
            title: Notification.title,
            text: cn(Notification.text, PaymentTimeLimit.text, RetrieveBooking.notification__text)
        }
    },
    DesktopSummaryFlight: {
        SummaryFlight: {
            flight: DesktopSummaryFlight.flight
        }
    },
    Contacts: {
        ContactsStyles: {
            contacts: cn(Checkout.contacts),
            form: Checkout.contacts__form
        }
    },
    Passenger: {
        Switch: {
            element: PassengerSwitch.element,
            element_active: PassengerSwitch.element_active,
            element_error: PassengerSwitch.element_error,
            error: PassengerSwitch.error
        }
    },
    Input: {
        Input: {
            real_input: Input.real_input,
            textField_filled: Input.textField_filled
        }
    },
    Modal: {
        Modal: {
            closeIcon: Modal.closeIcon,
            modal_rounded: Modal.modal_rounded
        }
    },
    InsurancePopup: {
        InsuranceCard: {
            header: InsurancePopup.header,
            header_info: InsurancePopup.header_info
        }
    },
    PromoLoader: {
        PromoLoader: {
            promo__content: PromoLoader.promo__content
        }
    },
    Checkin: {
        Checkin: {
            servicePrice: Checkin.servicePrice
        },
        Inital: {
            wrapper: CheckinInitial.wrapper,
            form: CheckinInitial.form,
            hint: CheckinInitial.hint
        },
        Passengers: {
            title: CheckinPassengers.title,
            segments: CheckinPassengers.segments,
            toolbar: CheckinPassengers.toolbar,
            toolbar__button: CheckinPassengers.toolbar__button
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            container: cn(RetrieveBooking.container, CheckinInitial.retrieveBooking),
            title: cn(RetrieveBooking.title, CheckinInitial.retrieveBooking__title),
            title__icon: RetrieveBooking.title__icon,
            button: RetrieveBooking.button,
            button_disabled: RetrieveBooking.button_disabled,
            form__row: RetrieveBooking.form__row
        }
    },
    CheckinRules: {
        CheckinRules: {
            rules__button: Checkin.rules__button
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            wrapper: SelectedPassenger.wrapper,
            passenger: SelectedPassenger.passenger,
            passenger__icon: SelectedPassenger.passengerIcon,
            passenger__name: SelectedPassenger.passengerName,
            passengerInfo: SelectedPassenger.passengerInfo,
            passengerInfo__item: SelectedPassenger.passengerInfo__item,
            passengerInfo__field: SelectedPassenger.passengerInfo__field,
            loyalty__link: SelectedPassenger.loyalty__link
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Segment.wrapper,
            wrapper_business: Segment.wrapper_business,
            header: Segment.header,
            city__weather: Segment.city__weather,
            flightInfo__item: Segment.flightInfo__item,
            flightInfo__title: Segment.flightInfo__title,
            flightInfo__value: Segment.flightInfo__value,
            city__airport: Segment.city__airport,
            city__info: Segment.city__info,
            city__iata: Segment.city__iata,
            city__time: Segment.city__time,
            plane__icon: Segment.plane__icon,
            airline__name: Segment.airline__name,
            footer: Segment.footer,
            footer__item: Segment.footer__item,
            footer__airline: Segment.footer__airline
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.wrapper,
            flightInfo: MobileSegment.flightInfo,
            flightInfo__iatas: MobileSegment.flightInfo__iatas,
            flightInfo__weather: MobileSegment.flightInfo__weather,
            flightInfo__dates: MobileSegment.flightInfo__dates,
            flightInfo__cities: MobileSegment.flightInfo__cities,
            flightInfo__time: MobileSegment.flightInfo__time,
            arrivalInfo: MobileSegment.arrivalInfo,
            fareGroup: MobileSegment.fareGroup,
            fareGroup__img: MobileSegment.fareGroup__img,
            fareGroup__info: MobileSegment.fareGroup__info,
            planeIcon: MobileSegment.planeInfo
        }
    },
    AddPassenger: {
        AddPassenger: {
            wrapper: AddPassenger.wrapper,
            form__wrapper: AddPassenger.formWrapper,
            form: AddPassenger.form,
            form__input: AddPassenger.form__input,
            addButton: AddPassenger.addButton,
            addButton__icon: AddPassenger.addButtonIcon,
            submitButton: AddPassenger.submitButton,
            passengerIcon: AddPassenger.passengerIcon
        }
    },
    RegisteredPassenger: {
        RegisteredPassenger: {
            wrapper: RegisteredPassenger.wrapper,
            header: RegisteredPassenger.header,
            passenger: RegisteredPassenger.passenger,
            seats: RegisteredPassenger.seats,
            seats__icon: RegisteredPassenger.seats__icon,
            footerActions: RegisteredPassenger.footerActions,
            footerActions__cancel: RegisteredPassenger.footerActions__cancel,
            actions__buttons: RegisteredPassenger.actions__buttons,
            appleWallet: RegisteredPassenger.appleWaller,
            downloadButton: RegisteredPassenger.downloadButton,
            downloadIcon: RegisteredPassenger.downloadIcon,
            actions__barcode: RegisteredPassenger.actions__barcode,
            actions__barcode_disabled: RegisteredPassenger.actions__barcode_disabled,
            services: RegisteredPassenger.services
        },
        SendToEmail: {
            wrapper: RegisteredPassenger.sendToEmail__wrapper,
            text: RegisteredPassenger.sendToEmail__text
        },
        Service: {
            service: RegisteredPassengerService.service,
            service__icon: RegisteredPassengerService.service__icon,
            service__info: RegisteredPassengerService.service__info,
            service__name: RegisteredPassengerService.service__name
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage: StatusMessage.wrapper,
            statusMessage_success: StatusMessage.statusMessage_success,
            statusMessage__title: StatusMessage.statusMessage__title,
            statusMessage__text: StatusMessage.statusMessage__text
        }
    },
    CustomerContactsForm: {
        CustomerContactsForm: {
            button: CustomerContactsForm.button
        },
        CustomerContactsModal: {
            saveContactInfo__modal: CustomerContactsForm.saveContactInfo__modal
        }
    },
    Datepicker: {
        MonthsWrapperStyles: {
            nav_next: MonthsWrapper.nav_next,
            nav_prev: MonthsWrapper.nav_prev
        }
    },
    Page404: {
        Page404: {
            error__code: Page404.error__code
        }
    },
    TicketRequest: {
        Breadcrumbs: {
            item: TicketRequest.breadcrumbs__item
        }
    },
    MobileAuthForm: {
        MobileAuthForm: {
            email: MobileAuthForm.email,
            button: MobileAuthForm.button
        }
    },
    LoginPage: {
        RegistrationForm: {
            checkbox: LoginPage.RegistrationForm__checkbox,
            checked: LoginPage.RegistrationForm__checkbox_checked
        }
    }
};
export default theme;
