import { CardType } from '@websky/core/src/PromoLoader/types';
import { getLocale } from '@websky/core/src/utils';
const currentLocale = getLocale();
const isProd = process.env.NODE_ENV === 'production';
const companyInfo = {
    iataCode: 'F7',
    privacyPolicy: `https://iflyltd.ru/${currentLocale}/privacy-policy/`,
    subsidyFaresConditionURL: '',
    exarePrivacyPolicyURL: '',
    exareRulesURL: ''
};
export const i18n = {
    en: {
        Title: {
            companyName: 'Airline iFly'
        },
        translation: {
            'Local time': 'Prices are for all passengers, including taxes and fees. By local time.'
        },
        Consents: {
            agreement_generalRules: `<span><a href='https://iflyltd.ru/en/privacy-policy/' target='_blank'>rules of purchase</a></span>`,
            agreement_gdsRules: '',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">rules of insurances</a>, {{insurancesProductsLinks}}'
        },
        Checkout: {
            'airline rules': 'Airline Rules',
            'as well as the': ' and ',
            'rules for purchasing subsidized tariffs': 'rules for purchasing subsidized tariffs',
            'I consent to the processing and storage of personal data in accordance with': 'I consent to the processing and storage of personal data in accordance with ',
            'processing of my personal data': 'privacy policy',
            'rules for purchasing additional services': 'rules for purchasing additional services'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email'
        },
        IncludedBaggage: {
            'Need to bring sports, music or baby equipments?': 'Do you need to bring sports equipment or a pet?'
        },
        CheckoutAdditionalService: {
            total: 'total'
        },
        SeatMap: {
            'Rules for providing of services': 'Rules for providing of services'
        },
        PromoLoader: {
            'Business Class on iFLY flights': 'Business Class on iFLY flights',
            'Feel the special service in flight and take advantage of the priority airport service!': 'Feel the special service in flight and take advantage of the priority airport service!'
        },
        CheckinConditions: {
            Rules_0: 'Online check-in opens 48 hours and closes 5 hours before departure',
            Rules_1: 'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm',
            Rules_2: 'Please, make sure te passenger is not included in special category'
        }
    },
    ru: {
        AdditionalBaggage: {
            'Buy now and save 20-30%': 'Сэкономьте до 30%'
        },
        Passengers: {
            Teenagers: 'Молодежь'
        },
        Title: {
            companyName: 'Авиакомпания iFly'
        },
        translation: {
            'Local time': 'Цены указаны для всех пассажиров, включая налоги и сборы. По местному времени.'
        },
        Consents: {
            agreement_generalRules: `<span><a href='https://iflyltd.ru/docs/rules.pdf' target='_blank'>правилами покупки</a></span>`,
            agreement_gdsRules: '',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">правилами страхования</a>, {{insurancesProductsLinks}}'
        },
        Checkout: {
            'I accept the': 'Я ознакомлен с',
            'airline rules': 'правилами Авиакомпании',
            'terms of tariff': 'условиями применения тарифа',
            'as well as the': ', а также с ',
            'rules for purchasing subsidized tariffs': 'правилами приобретения субсидируемых тарифов',
            'I consent to the processing and storage of personal data in accordance with': 'Я разрешаю обработку и хранение персональных данных согласно ',
            'processing of my personal data': 'политике конфиденциальности',
            'rules for purchasing additional services': 'правилами приобретения дополнительных услуг'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта'
        },
        IncludedBaggage: {
            'Need to bring sports, music or baby equipments?': 'Вам нужно взять с собой спортивное оборудование или домашнее животное?'
        },
        CheckoutAdditionalService: {
            total: 'итого'
        },
        SeatMap: {
            'Rules for providing of services': 'Правила предоставления услуг'
        },
        PromoLoader: {
            'Business Class on iFLY flights': 'Бизнес-класс на рейсах iFLY',
            'Feel the special service in flight and take advantage of the priority airport service!': 'Ощутите особый сервис в полете и воспользуйтесь приоритетным обслуживанием аэропорту!'
        },
        CheckinConditions: {
            Rules_0: 'Онлайн регистрация открывается за 48 часов и закрывается за 5 часов до вылета.',
            Rules_1: 'Регистрация багажа закрывается за 40 минут до вылета рейса. Пожалуйста, проверьте, что вес вашего ручного багажа не превышает 10 кг, а размеры не превышают 55x40x23 см.',
            Rules_2: 'Пожалуйста, убедитесь, что пассажир не относится к специальной категории.'
        }
    }
};
export const promoLoaders = [
    {
        type: CardType.City,
        title: 'Business Class on iFLY flights',
        text: 'Feel the special service in flight and take advantage of the priority airport service!',
        image: 'https://cdn.websky.aero/content/frontend/images/ifly/business_class.jpg'
    },
    {
        type: CardType.Meal,
        title: 'Business Class on iFLY flights',
        text: 'Feel the special service in flight and take advantage of the priority airport service!',
        image: 'https://cdn.websky.aero/content/frontend/images/ifly/business_class.jpg'
    },
    {
        type: CardType.Aircraft,
        title: 'Business Class on iFLY flights',
        text: 'Feel the special service in flight and take advantage of the priority airport service!',
        image: 'https://cdn.websky.aero/content/frontend/images/ifly/business_class.jpg'
    },
    {
        type: CardType.Seats,
        title: 'Business Class on iFLY flights',
        text: 'Feel the special service in flight and take advantage of the priority airport service!',
        image: 'https://cdn.websky.aero/content/frontend/images/ifly/business_class.jpg'
    },
    {
        type: CardType.WiFi,
        title: 'Business Class on iFLY flights',
        text: 'Feel the special service in flight and take advantage of the priority airport service!',
        image: 'https://cdn.websky.aero/content/frontend/images/ifly/business_class.jpg'
    }
];
export const iflyPassengersConfig = [
    {
        code: 'ADT',
        isAdult: true,
        defaultCount: 1
    },
    {
        code: 'YTH',
        parentCode: 'ADT',
        isAdult: false,
        maxCountWithoutAdult: 9
    },
    {
        code: 'SCF',
        parentCode: 'ADT',
        isAdult: false,
        maxCountWithoutAdult: 9
    },
    {
        code: 'SCM',
        parentCode: 'ADT',
        isAdult: false,
        maxCountWithoutAdult: 9
    },
    {
        code: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 3,
        noAvailableWith: ['YTH', 'SRC']
    },
    {
        code: 'INF',
        isAdult: false,
        noAvailableWith: ['YTH', 'SRC']
    },
    {
        code: 'INS',
        isAdult: false,
        noAvailableWith: ['YTH', 'SRC']
    }
];
export const config = {
    global: {
        yandexMetrikaId: '89231648',
        companyInfo,
        showIsActualizationResultOk: false,
        i18n,
        findOrderURL: '',
        seatMap: {
            showRecommendedSeats: true,
            splitByCabin: true
        },
        authMethods: {
            phone: false,
            ffp: false
        },
        reserveEngines: {
            siteUrl: 'https://iflyltd.ru/'
        },
        insurance: {
            upsaleStrategy: ['checkout-insurance-step', 'checkout-order-service', 'checkout-before-payment']
        }
    },
    Account: {
        engineURL: './booking',
        showUserPhoneInput: false
    },
    Engine: {
        calendarDaysCount: 7,
        searchFormURL: isProd ? './' : null,
        checkInURL: './check-in',
        checkInEnabled: true,
        showAvailableFaresOnly: true,
        resultsFareSelectorInModal: false,
        promoLoaders
    },
    SearchForm: {
        passengersConfig: iflyPassengersConfig,
        payByMiles: false,
        showPricesInDatepicker: true,
        selectableCurrencies: [
            {
                code: 'RUB',
                symbol: '₽'
            },
            {
                code: 'USD',
                symbol: '$'
            },
            {
                code: 'EUR',
                symbol: '€'
            },
            {
                code: 'CNY',
                symbol: '¥'
            }
        ],
        mobileFormType: 'alternative'
    },
    FlightStatus: {
        playMarketURL: '',
        appStoreURL: ''
    }
};
