import * as React from 'react';
import RootComponent from '@websky/core/src/SeatMap/components/SeatMapService/SeatMapService';
import SeatsInfo from './SeatsInfo/SeatsInfo';
import { cabinCodeComfortMap } from '@websky/core/src/SeatMap/components/SeatMap/utils';
import { SeatComfort } from '@websky/graphql';
const SeatMap = props => {
    const renderSeatsInfo = React.useCallback((services, segmentIndex) => {
        const seatsBySegment = {};
        let isBusinessCabin = null;
        for (const deck of props.seatMaps.segments[segmentIndex].decks) {
            for (const row of deck.rows) {
                if (row.seats.some(seat => seat.isAvailable) && !isBusinessCabin) {
                    isBusinessCabin = cabinCodeComfortMap.get(row.cabinCode) === SeatComfort.Comfort; // cabinCode === Y
                }
                for (const seat of row.seats) {
                    if (seat.rfisc && seat.isAvailable) {
                        const segmentInfo = services.find(service => service.rfisc === seat.rfisc);
                        seatsBySegment[seat.rfisc] = Object.assign(Object.assign({}, seat), { name: segmentInfo.name, serviceImage: segmentInfo.serviceImage, description: segmentInfo.description, service: seat.seatService });
                    }
                }
            }
        }
        return (React.createElement(SeatsInfo, { passenger: props.passengers[0], seatsByRfisc: seatsBySegment, showFreeSeat: isBusinessCabin, showComfort: false }));
    }, [props.passengers]);
    return React.createElement(RootComponent, Object.assign({}, props, { renderSeatsInfo: renderSeatsInfo }));
};
export default SeatMap;
